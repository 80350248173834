import * as React from "react";
import Layout from "../components/Layout";
import { Flex, Text, Heading, Link } from "@chakra-ui/react";
import "@fontsource/montserrat/100.css";
import "@fontsource/montserrat/200.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";
import "@fontsource/montserrat/900.css";
import "@fontsource/work-sans/100.css";
import "@fontsource/work-sans/200.css";
import "@fontsource/work-sans/300.css";
import "@fontsource/work-sans/400.css";
import "@fontsource/work-sans/500.css";
import "@fontsource/work-sans/600.css";
import "@fontsource/work-sans/700.css";
import "@fontsource/work-sans/800.css";
import "@fontsource/work-sans/900.css";
import "@fontsource/cardo/400.css";
import "@fontsource/cardo/700.css";
import "@fontsource/square-peg";
const Faq = ({ location }) => {
  return (
    <Layout bgColor="white" path={location.pathname}>
      <Flex
        direction="column"
        py="30px"
        maxW="800px"
        minH="65vh"
        fontFamily="Work Sans"
        margin="auto"
        px="30px"
      >
        <Heading
          w="100%"
          color="green.500"
          as="h1"
          mb="4"
          fontFamily="Montserrat"
        >
          Frequently Asked Questions
        </Heading>
        {/* <Text variant="faqText" mb="6">
          This page will be used to connect to different FAQs concerning MCI and
          Dementia related questions.
        </Text> */}

        <Flex direction="column" mb="6">
          <Text
            fontWeight="600"
            variant="faqText"
            fontSize={{ base: "12px", md: "14px", lg: "15px" }}
            fontFamily="Montserrat"
            color="blue"
            as={Link}
            href="https://www.nia.nih.gov/health/frequently-asked-questions-about-caregiving"
            mb="1"
          >
            Caregiving
          </Text>

          <Text
            fontSize={{ base: "10px", md: "10px", lg: "12px" }}
            as={Link}
            href="https://www.nia.nih.gov/"
          >
            Source: National Institute of Aging
          </Text>
        </Flex>
        <Flex direction="column" mb="6">
          <Text
            fontWeight="600"
            fontSize={{ base: "12px", md: "14px", lg: "15px" }}
            fontFamily="Montserrat"
            color="blue"
            as={Link}
            href="https://gocogno.com/frequently-asked-questions-mild-cognitive-impairment/"
            mb="1"
          >
            Mild Cognitive Impairment - MCI
          </Text>

          <Text
            fontSize={{ base: "10px", md: "10px", lg: "12px" }}
            as={Link}
            href="https://gocogno.com/"
          >
            Source: Go Cogno
          </Text>
        </Flex>
        <Flex direction="column" mb="6">
          <Text
            fontWeight="600"
            variant="faqText"
            fontSize={{ base: "12px", md: "14px", lg: "15px" }}
            fontFamily="Montserrat"
            color="blue"
            as={Link}
            href="https://memory.ucsf.edu/sites/memory.ucsf.edu/files/wysiwyg/UCSF%20Dementia%20Patient%20Guide_Dementia_11-3-17.pdf"
            mb="1"
          >
            A Patient's Guide to Mild Cognitive Impairment - MCI
          </Text>

          <Text
            fontSize={{ base: "10px", md: "10px", lg: "12px" }}
            as={Link}
            href="https://weill.ucsf.edu/"
          >
            Source: UCSF Weill Institute
          </Text>
        </Flex>
        <Flex direction="column" mb="6">
          <Text
            fontWeight="600"
            variant="faqText"
            fontSize={{ base: "12px", md: "14px", lg: "15px" }}
            fontFamily="Montserrat"
            color="blue"
            as={Link}
            href="https://www.caregiver.org/resource/caregivers-guide-understanding-dementia-behaviors/"
            mb="1"
          >
            Caregiver’s Guide to Understanding Dementia Behaviors{" "}
          </Text>

          <Text
            fontSize={{ base: "10px", md: "10px", lg: "12px" }}
            as={Link}
            href="https://www.caregiver.org"
          >
            Source: Family Caregiver Alliance
          </Text>
        </Flex>
        <Flex direction="column" mb="6">
          <Text
            fontWeight="600"
            variant="faqText"
            fontSize={{ base: "12px", md: "14px", lg: "15px" }}
            fontFamily="Montserrat"
            color="blue"
            as={Link}
            href="https://www.yalemedicine.org/news/dementia-questions"
            mb="1"
          >
            Dementia
          </Text>

          <Text
            fontSize={{ base: "10px", md: "10px", lg: "12px" }}
            as={Link}
            href="https://www.yalemedicine.org/"
          >
            Source: Yale Medicine
          </Text>
        </Flex>
        <Flex direction="column" mb="6">
          <Text
            fontWeight="600"
            variant="faqText"
            fontSize={{ base: "12px", md: "14px", lg: "15px" }}
            fontFamily="Montserrat"
            color="blue"
            as={Link}
            href="https://www.alzint.org/about/dementia-facts-figures/frequently-asked-questions-around-dementia/"
            mb="1"
          >
            Dementia
          </Text>

          <Text
            fontSize={{ base: "10px", md: "10px", lg: "12px" }}
            as={Link}
            href="https://www.alzint.org/"
          >
            Source: Alzheimer's Disease International
          </Text>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default Faq;
